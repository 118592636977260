import {
  Link,
  Typography,
  Toolbar,
  Container,
  Paper,
} from "@mui/material";
import { AppToolbar } from "./AppToolbar";
import { Map, YMaps, Placemark } from "react-yandex-maps";

const PageSection = (props) => (
  <Paper sx={{ mt: 3, px: 4, pt: 2, pb: 3 }}>{props.children}</Paper>
);

function App() {
  return (
    <>
      <AppToolbar />
      <Toolbar sx={{height: 200}} />
      <Container>
        <PageSection>
          <Typography variant="h1">ТЕЛЕМАСТЕРСКАЯ В ЮЖНОМ</Typography>
          <Typography variant="h2">Услуги</Typography>
          <Typography variant="h3">
            <ul>
              <li>Ремонт LCD-телевизоров. Низкие цены на ремонт.</li>
              <li>Ремонт пультов дистанционного управления</li>
              <li>Ремонт бытовой электроники</li>
            </ul>
          </Typography>
          <Typography variant="h2">Продаём</Typography>
          <Typography variant="h3">
            <ul>
              <li>Подержанные телевизоры.</li>
              <li>Пульты дистанционного управления</li>
            </ul>
          </Typography>
          Оплату принимаем наличными, либо онлайн переводом на счёт в Сбербанке.
        </PageSection>
        <PageSection>
          <Typography variant="h1">Контакты</Typography>
          <br />
          <Typography marginTop={2}>
            Телефон: <Link underline="none" color="inherit" href="tel:+79040289741">+7 (904) 028-97-41</Link>
            <br />
            <br />
            Адрес нашей мастерской: г.Тверь, пр. Октябрьский, 99, секция 4
            <br />
          </Typography>
          <br />

          <YMaps>
            <Map
              width="100%"
              height={400}
              defaultState={{ center: [56.817074, 35.889125], zoom: 13 }}
            >
              <Placemark defaultGeometry={[56.817074, 35.889125]} />
            </Map>
          </YMaps>
          <img src="/dom1.jpg" alt="Фото дома" />
          <img src="/dver1.jpg" alt="Фото входной двери" />
          <br />
        </PageSection>
      </Container>
    </>
  );
}

export default App;
